.MainDash{
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.MainDash > h1{
  margin-bottom: 0;
}


@media (max-width: 1200px) {
  .MainDash{
      justify-content:flex-start;
      margin-top: 1rem;
  }
}

@media (max-width: 768px)
{
  .MainDash{
    margin-right: 8px;
    margin-left: 40%;
  }
}
