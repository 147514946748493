.Cards {
  display: flex;
  gap: 10px;
}

@media (max-width: 1200px) {
  .Cards{
    flex-direction: column;
  }
}

