.CompactCard {
  display: flex;
  flex: 1;
  height: 6rem !important;
  border-radius: 0.7rem;
  color: white;
  padding: 1rem;
  position: relative;
  cursor: pointer;
}
.CompactCard:hover {
  box-shadow: none !important;
}

/* radial bar */
.radialBar {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 1rem;
}
.CircularProgressbar {
  width: 4rem !important;
  overflow: visible;
}
.CircularProgressbar-path {
  stroke: white !important;
  stroke-width: 12px !important;
  filter: drop-shadow(2px 4px 6px white);
}
.CircularProgressbar-trail {
  display: none;
}
.CircularProgressbar-text {
  fill: white !important;
}

.radialBar > span {
  font-size: 18px;
  font-weight: bold;
}

/* sideDetail*/
.detail {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
}

.detail > span:nth-child(2) {
  font-size: 24px;
  font-weight: bold;
}
.detail > span:nth-child(3) {
  font-size: 12px;
  font-weight: bold;
}
